import react from 'react';
import styled, { css } from 'styled-components';
import { Link as StyledLink } from 'gatsby';

import { Content } from '../Section';
import { spartanBold, spartanLight } from '~/mixins/typography';
import { centerIcon } from '~/mixins/images';
import {
  ICON_FACEBOOK,
  ICON_PHONE,
  ICON_MENU,
  ICON_CLOSE,
  ICON_LOGO,
} from '~/constants/icons';
import { MOBILE_VIEW, PRIMARY_COLOR } from '~/constants/design';
import { noUserSelect } from '~/mixins/user-selection';

export const StyledAppbar = styled.div`
  width: 100%;
  background-color: #fff;
`;

export const Container = styled(Content)`
  height: 128px;
  margin: 0 auto;
  display: flex;
  align-items: center;
`;

export const StyledHeader = styled(StyledLink)`
  display: flex;
  align-items: center;
  ${noUserSelect()};
`;

export const Logo = styled.div`
  width: 72px;
  height: 72px;
  background-image: url(${ICON_LOGO});
  ${centerIcon()};
`;

export const NameContainer = styled.div`
  font-size: 16px;
  margin-left: 16px;
`;

export const Sublabel = styled.div`
  ${spartanLight()};
`;

export const LinksContainer = styled.div`
  margin-left: auto;

  ${({ visible }: { visible: boolean }) =>
    visible &&
    css`
      @media (max-width: ${MOBILE_VIEW}px) {
        width: 100%;
        opacity: 1 !important;
        pointer-events: auto !important;
      }
    `}

  @media (max-width: ${MOBILE_VIEW}px) {
    position: fixed;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    background-color: #fff;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 64px 0px;
    z-index: 10;
  }
`;

const linkStyle = css`
  font-size: 16px;
  will-change: color;
  transition: 0.1s color;
  ${noUserSelect()};
  ${spartanBold()};

  @media (min-width: ${MOBILE_VIEW + 1}px) {
    &:not(:first-child) {
      margin-left: 24px;
    }
  }

  @media (max-width: ${MOBILE_VIEW}px) {
    width: 100%;
    padding: 0px 16px;
    font-size: 20px;
    text-align: center;
  }

  &:hover {
    color: ${PRIMARY_COLOR};
  }
`;

export const Link = styled(StyledLink)`
  ${linkStyle};
`;

export const ExternalLink = styled.a`
  ${linkStyle}
`;

export const activeLinkStyle: React.CSSProperties = {
  color: PRIMARY_COLOR,
};

export const StyledContact = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;

  @media (max-width: ${MOBILE_VIEW}px) {
    display: none;
  }
`;

export const Divider = styled.div`
  width: 1px;
  height: 24px;
  margin-left: 8px;
  margin-right: 24px;
  background-color: rgba(0, 0, 0, 0.12);
`;

export const PhoneIcon = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${ICON_PHONE});
  ${centerIcon(20)};
`;

export const PhoneNumber = styled.div`
  font-size: 14px;
  white-space: nowrap;
  margin-left: 8px;
  user-select: all;
`;

const iconBase = css`
  width: 48px;
  height: 48px;
  cursor: pointer;
  border-radius: 100%;
  ${centerIcon(24)};
  will-change: background-color;
  transition: 0.1s background-color;

  &:hover {
    background-color: rgba(0, 0, 0, 0.06);
  }
`;

const Icon = styled.div`
  display: none;
  ${iconBase};

  @media (max-width: ${MOBILE_VIEW}px) {
    display: block;
  }
`;

export const MenuIcon = styled(Icon)`
  background-image: url(${ICON_MENU});
  margin-left: auto;
`;

export const CloseIcon = styled(Icon)`
  background-image: url(${ICON_CLOSE});
`;

export const FacebookIcon = styled.a`
  background-image: url(${ICON_FACEBOOK});
  ${iconBase};
  ${centerIcon(12)};
`;
