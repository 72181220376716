import styled, { createGlobalStyle } from 'styled-components';

import { noButtons } from '~/mixins/scroll';
import { body2, spartanBold } from '~/mixins/typography';
import { fonts } from '~/constants/fonts';
import { noTapHighlight, noUserSelect } from '~/mixins/user-selection';

export const Style = createGlobalStyle`
  html,
  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    color: #000;
    backface-visibility: hidden;
    -webkit-font-smoothing: antialiased;
    background-color: #FAFAFC;
    ${body2()};
    ${noTapHighlight()};
    ${noButtons({
      size: 10,
      color: 'rgba(0, 0, 0, 0.48)',
      hoverColor: 'rgba(0, 0, 0, 0.64)',
    })};
  }

  @font-face {
    font-family: 'Spartan';
    font-style: normal;
    font-weight: 300;
    src: url(${fonts.spartanLight}) format('woff2');
  }

  @font-face {
    font-family: 'Spartan';
    font-style: normal;
    font-weight: 400;
    src: url(${fonts.spartanRegular}) format('woff2');
  }

  @font-face {
    font-family: 'Spartan';
    font-style: normal;
    font-weight: 600;
    src: url(${fonts.spartanBold}) format('woff2');
  }

  * {
    box-sizing: border-box;
  }

  b {
    ${spartanBold()};
  }

  a {
    color: #000;
    text-decoration: none;
  }
`;

export const Main = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 64px;
`;

export const Label = styled.div`
  font-size: 32px;
  text-align: center;
  padding: 64px 0px;
  color: #000;
  ${noUserSelect()};
`;
