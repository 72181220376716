import React from 'react';

import { StyledFooter } from './style';

export const Footer = () => {
  return (
    <StyledFooter>
      <a href="https://github.com/xnerhu">© 2020 Mikołaj Palkiewicz</a>
    </StyledFooter>
  );
};
