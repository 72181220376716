import React from 'react';

import Seo from '../Seo';
import { Appbar } from '../Appbar';
import { Banner } from '../Banner';
import { Footer } from '../Footer';
import { Style, Main, Label } from './style';

interface Props {
  label: string;
  bannerImg?: string;
  bannerPortrait?: boolean;
  bannerDisabled?: boolean;
  children: React.ReactNode;
}

export default ({
  label,
  bannerImg,
  bannerPortrait,
  bannerDisabled,
  children,
}: Props) => {
  return (
    <>
      <Seo />
      <Style />
      <Main>
        <Appbar />
        {!bannerDisabled && (
          <Banner src={bannerImg} portraitMode={bannerPortrait} />
        )}
        <Label>{label}</Label>
        {children}
      </Main>
      <Footer />
    </>
  );
};
