import React from 'react';

import { navigation } from '~/constants/navigation';
import { MOBILE_VIEW } from '~/constants/design';
import { INavItem } from '~/interfaces';
import {
  StyledAppbar,
  StyledHeader,
  Logo,
  Sublabel,
  LinksContainer,
  Link,
  StyledContact,
  FacebookIcon,
  Divider,
  PhoneIcon,
  PhoneNumber,
  MenuIcon,
  CloseIcon,
  Container,
  NameContainer,
  activeLinkStyle,
  ExternalLink,
} from './style';

export const Header = () => {
  return (
    <StyledHeader to="/">
      <Logo />
      <NameContainer>
        <Sublabel>PSYCHOLOG</Sublabel>
        <b>Anna Gębczak</b>
      </NameContainer>
    </StyledHeader>
  );
};

const Contact = () => {
  return (
    <StyledContact>
      <FacebookIcon href="https://www.facebook.com/psychologannagebczak" />
      <Divider />
      <PhoneIcon />
      <PhoneNumber>+48 510 710 752</PhoneNumber>
    </StyledContact>
  );
};

interface INavLinkProps {
  data: INavItem;
  onClick: () => void;
}

const NavLink = ({ data, onClick }: INavLinkProps) => {
  const { external, path, label } = data;

  if (external)
    return (
      <ExternalLink href={path} onClick={onClick}>
        {label}
      </ExternalLink>
    );
  return (
    <Link
      partiallyActive={path !== '/'}
      activeStyle={activeLinkStyle}
      to={path}
      onClick={onClick}
    >
      {label}
    </Link>
  );
};

export const Appbar = () => {
  const [menuVisible, toggleMenu] = React.useState(false);

  const onWindowResize = React.useCallback(() => {
    if (window.innerWidth > MOBILE_VIEW && menuVisible) {
      toggleMenu(false);
    }
  }, [menuVisible]);

  React.useEffect(() => {
    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [onWindowResize]);

  React.useEffect(() => {
    document.body.style.overflowY = menuVisible ? 'hidden' : 'unset';
  }, [menuVisible]);

  const onLinkClick = React.useCallback(() => {
    toggleMenu(false);
  }, []);

  return (
    <StyledAppbar>
      <Container>
        <Header />
        <LinksContainer visible={menuVisible}>
          {navigation.map((r) => (
            <NavLink key={r.label} onClick={onLinkClick} data={r} />
          ))}
          <CloseIcon onClick={() => toggleMenu(false)} />
        </LinksContainer>
        {/* <Contact /> */}
        <MenuIcon onClick={() => toggleMenu(true)} />
      </Container>
    </StyledAppbar>
  );
};
