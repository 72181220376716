import React from 'react';
import { Helmet } from 'react-helmet';

import { PRIMARY_COLOR } from '~/constants/design';

export default () => {
  return (
    <Helmet
      htmlAttributes={{
        lang: 'pl',
      }}
      title="Psycholog - Anna Gębczak"
      meta={[
        {
          name: 'description',
          content: `Jestem psychologiem i psychoterapeutą psychoanalitycznym w trakcie szkolenia w Studium
          Psychoterapii Analitycznej rekomendowanym przez Polskie Towarzystwo Psychoterapii
          Psychoanalitycznej.`,
        },
        {
          name: 'keywords',
          content:
            'opole, psycholog, psychoterapeta,  psychoanalityczny, psychoterapia, anna gębczak',
        },
        {
          name: 'theme-color',
          content: PRIMARY_COLOR,
        },
        {
          name: 'author',
          content: 'Mikołaj Palkiewicz',
        },
      ]}
    />
  );
};
