import React from 'react';

import { IMAGE_BANNER } from '~/constants/images';
import { StyledBanner, Image } from './style';

interface Props {
  src?: string;
  portraitMode?: boolean;
}

export const Banner = ({ src, portraitMode }: Props) => {
  return (
    <StyledBanner
      portraitMode={portraitMode}
      style={!portraitMode ? { backgroundImage: `url(${src})` } : {}}
    >
      {portraitMode && <Image src={src} />}
    </StyledBanner>
  );
};

Banner.defaultProps = {
  src: IMAGE_BANNER,
} as Props;
