import styled, { css } from 'styled-components';

import {
  CONTENT_MAX_WIDTH,
  CARD_SHADOW,
  MOBILE_VIEW,
} from '~/constants/design';

export const Content = styled.div`
  width: calc(100% - 128px);
  max-width: ${CONTENT_MAX_WIDTH}px;
  margin: 0 auto;
  text-align: justify;

  @media (max-width: ${MOBILE_VIEW}px) {
    width: calc(100% - 64px);
  }
`;

export const CardCss = css`
  border-radius: 12px;
  padding: 32px 64px;
  font-size: 16px;
  line-height: 40px;
  color: rgba(0, 0, 0, 0.89);
  box-shadow: ${CARD_SHADOW};

  @media (max-width: ${MOBILE_VIEW}px) {
    padding: 32px;
  }
`;

export const Card = styled(Content)`
  ${CardCss};
`;

export const Break = styled.div`
  width: 100%;
  margin: 24px 0px;
`;
