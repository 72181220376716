import styled, { css } from 'styled-components';

export const StyledBanner = styled.div`
  width: 100%;
  height: 384px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;

  ${({ portraitMode }: { portraitMode: boolean }) =>
    !portraitMode
      ? css`
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background-color: rgba(0, 0, 0, 0.32);
          }
        `
      : css`
          margin-top: 32px;
          height: 512px;

          & img {
            border-radius: 12px;
            overflow: hidden;
          }
        `};
`;

export const Image = styled.img`
  height: 100%;
  object-fit: cover;
  object-position: center;
`;
