import { INavItem } from '~/interfaces';

export const navigation: INavItem[] = [
  {
    label: 'O mnie',
    path: '/',
  },
  {
    label: 'Psychoterapia Analityczna',
    path: '/psychoterapia-analityczna',
  },
  {
    label: 'Oferta',
    path: '/oferta',
  },
  {
    label: 'Kontakt',
    path: '/kontakt',
  },
  {
    label: 'Facebook',
    path: 'https://www.facebook.com/psychologannagebczak/',
    external: true,
  },
];
